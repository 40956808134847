import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

const myErrorHandler = ({ error, event }:any) => {
	window.alert('Apologies, an error occurred: '+JSON.stringify(error,null,2)+'\n'+JSON.stringify(event,null,2));
};

export const handleError = handleErrorWithSentry(myErrorHandler);

Sentry.init({
	dsn: 'https://297d1a78cbe84b02887d3fbfbf932901@o1134168.ingest.sentry.io/4504789932572672',
	tracesSampleRate: 1.0,
	// For instance, initialize Session Replay:
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [new Sentry.Replay()]
});
